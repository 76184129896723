import { Button, Grid, InputAdornment, TextField, Typography, useMediaQuery } from '@mui/material';
import React, {  useState } from 'react';
import emailjs from 'emailjs-com';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';


const SERVICE_ID = 'service_r1upc8p';
const TEMPLATE_ID = 'template_o3f6y9h';
const USER_ID = 'BYnD6lRrcDdjsaoXt';

export default function SupportComponentPage() {
    const [submitted, setSubmitted] = useState(false);

    const [values, setValues] = useState({
        firstName: '',
        lastName: '',
        phone: '',
        company: '',
        email: '',
        comment: '',
    });

    //Validation Check
    const validationCheck = Object.values(values).includes('');

    const handleFirstNameInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            firstName: event.target.value,
        }));
    };

    const handleLastNameInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            lastName: event.target.value,
        }));
    };

    const handleCompanyInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            company: event.target.value,
        }));
    };

    const handlePhoneInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            phone: event.target.value,
        }));
    };

    const handleEmailInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            email: event.target.value,
        }));
    };

    const handleCommentInputChange = (event) => {
        event.persist();
        setValues((values) => ({
            ...values,
            comment: event.target.value,
        }));
    };

    const handleSubmit = (e) => {
        setSubmitted(true);
        e.preventDefault();
        emailjs
            .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
            .then((result) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Success',
                    text: 'Your message has been sent, we will respond shortly.',
                    confirmButtonColor: '#5cb5b3',
                });
            });
        e.target.reset();
    };

    return (
        <Grid sx={{ marginTop: '30px' }}>
            <Grid sx={{ paddingLeft: '0px' }}>
                <Grid>
                    {' '}
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Need help? Email us at {''}
                        <Link
                            style={{
                                color: '#787878',
                                textDecoration: 'underline',
                            }}
                            to='#'
                            // @ts-ignore
                            onClick={() =>
                                // @ts-ignore
                                (window.location = 'mailto: support@7-day.com')
                            }
                        >
                            support@7-day.com
                        </Link>{' '}
                        or complete the below form.
                    </Typography>
                </Grid>

            
                    <Grid sx={{ width: '100%' }}>
                        <form onSubmit={handleSubmit}>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // backgroundColor: 'red',
                                }}
                            >
                                <Grid sx={{ width: '50%' }}>
                                    <TextField
                                        label='First'
                                        name='user_first'
                                        fullWidth
                                        value={values.firstName}
                                        onChange={handleFirstNameInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ width: '50%', paddingLeft: '5px' }}>
                                    <TextField
                                        label='Last'
                                        name='user_last'
                                        fullWidth
                                        // sx={{ paddingLeft: '10px' }}
                                        value={values.lastName}
                                        onChange={handleLastNameInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    // backgroundColor: 'red',
                                    paddingTop: '20px',
                                }}
                            >
                                <Grid sx={{ width: '50%' }}>
                                    <TextField
                                        label='Company'
                                        name='user_company'
                                        fullWidth
                                        value={values.company}
                                        onChange={handleCompanyInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid sx={{ width: '50%', paddingLeft: '5px' }}>
                                    <TextField
                                        label='Phone'
                                        name='user_phone'
                                        fullWidth
                                        // sx={{ paddingLeft: '10px' }}
                                        value={values.phone}
                                        onChange={handlePhoneInputChange}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position='start'></InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                            </Grid>

                            <Grid
                                sx={{
                                    paddingTop: '20px',
                                    width: '100%',
                                    // backgroundColor: 'blue',
                                }}
                            >
                                <TextField
                                    label='Email'
                                    name='user_email'
                                    value={values.email}
                                    fullWidth
                                    onChange={handleEmailInputChange}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'></InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid sx={{ paddingTop: '20px' }}>
                                <TextField
                                    label='Message'
                                    name='user_message'
                                    // value={values.comment}
                                    fullWidth
                                    onChange={handleCommentInputChange}
                                    multiline
                                    rows={4}
                                    // maxRows={4}
                                    inputProps={{
                                        maxLength: 150,
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'></InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            {/* <ReCAPTCHA
                                            ref={captchaRef}
                                            sitekey={
                                                process.env.REACT_APP_SITE_KEY
                                            }
                                        /> */}

                            <Button
                                sx={{
                                    marginTop: '20px',
                                    marginLeft: '10px',
                                    fontFamily: 'Open Sans',
                                }}
                                disabled={validationCheck === true}
                                variant='contained'
                                type='submit'
                            >
                                Submit
                            </Button>
                        </form>
                    </Grid>
          
            </Grid>
        </Grid>
    );
}
