import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ArrowRightAltSharpIcon from '@mui/icons-material/ArrowRightAltSharp';
import Stack from '@mui/material/Stack';
import { Link } from 'react-scroll';
import { Grid, useMediaQuery } from '@mui/material';
import { isEdge } from 'react-device-detect';

export const HomeComponent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const [isHover, setHover] = useState(false);

    const handleMouseHover = (e) => {
        if (e._reactName === 'onMouseEnter') {
            setHover(true);
        } else {
            setHover(false);
        }
    };


    return (
        <Grid
            id='home'
            sx={{
                backgroundImage: `url(${'/imgs/islandpc.jpg'})`,
                resizeMode: 'cover',
                backgroundPosition: isMobile
                    ? '-800px -1250px'
                    : isEdge && window.innerWidth > 2000 
                    ? '-200px -3250px'
                    : isEdge && window.innerWidth < 1000 ? '-200px -3150px'
                    : window.innerWidth < 2560 && window.innerWidth > 1030
                    ? '-200px -1250px'
                    : '-400px -1250px',
                width: '100%',
                height: isMobile ? '100vh' : isIpad ? '90vh' : '100vh',
                backgroundSize: isMobile
                    ? '1500px' 
                    : isIpad
                    ? '2000px': isEdge && window.innerWidth > 2000 ? '4100px'
                    : isEdge && window.innerWidth < 1000 ? '2100px'
                    : window.innerWidth < 2560 && window.innerWidth > 1030
                    ? '3500px'
                    : 'auto',
                backgroundPositionX: isMobile
                    ? -255
                    : isIpad
                    ? -155 : isEdge && window.innerWidth > 2000 ? -350
                    : isEdge && window.innerWidth < 1000 ? -200
                    : window.innerWidth < 2560 && window.innerWidth > 1030
                    ? 0
                    : -550,
                backgroundPositionY: isMobile
                    ? -50
                    : isIpad
                    ? -25
                    : window.innerWidth < 2560 && window.innerWidth > 1030
                    ? -870 
                    : '',
            }}
        >
            <Box
                sx={{
                    height: isMobile || isIpad ? '100vh' : '110vh',
                    backgroundColor: 'transparent',
                    width: isMobile || isIpad ? '100%' : '95%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignContent: 'left',
                    alignItems: 'left',
                    justifyContent: isMobile || isIpad ? 'none' : 'center',
                }}
            >
                <Grid
                    sx={{
                        padding:
                            isMobile || isIpad
                                ? '150px 0px 0px 20px'
                                : '0px 0px 0px 20px',
                    }}
                >
                    <Typography
                        component='h1'
                        variant={isMobile ? 'h4' : 'h2'}
                        noWrap
                        sx={{
                            // fontSize: isMobile ? 40:60,
                            color: 'white',
                            fontFamily: 'Open Sans',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Making it Work,
                    </Typography>
                    <Typography
                        component='h1'
                        variant={isMobile ? 'h4' : 'h2'}
                        noWrap
                        sx={{
                            // fontSize: isMobile ? 40:60,
                            color: 'white',
                            fontFamily: 'Open Sans',
                            letterSpacing: '-0.5px',
                        }}
                    >
                        Making it Fun
                    </Typography>
                    <Box
                        sx={{
                            width: '100%',
                            flexDirection: 'row',
                            display: 'flex',
                            padding: '60px 0px 00px 6px',
                        }}
                    >
                        <Link
                            to={'vision'}
                            spy={true}
                            smooth={true}
                            offset={100}
                            duration={2000}
                            delay={350}
                        >
                            {' '}
                            <Stack
                                sx={{
                                    textDecoration: 'none',
                                    color:
                                        isHover === true ? 'yellow' : '#787878',
                                    transition:
                                        isHover === true
                                            ? 'all 0.5s ease'
                                            : 'none',
                                }}
                                component='a'
                                direction='row'
                                alignItems='center'
                                gap={2}
                                onMouseEnter={(e) => handleMouseHover(e)}
                                onMouseLeave={(e) => handleMouseHover(e)}
                            >
                                <Typography
                                    variant='body1'
                                    sx={{
                                        fontSize: 15,
                                        fontFamily: 'Open Sans',
                                        letterSpacing: '.4rem',
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                >
                                    DISCOVER
                                </Typography>
                                <ArrowRightAltSharpIcon />{' '}
                            </Stack>
                        </Link>
                    </Box>{' '}
                </Grid>
            </Box>
        </Grid>
    );
};
