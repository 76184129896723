import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';


export default function ManagingAUser() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
       
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            If you're an admin, you can edit your staff's details and
                            manage your staff's access to the site with two
                            different levels of access.
                            <ul>
                                <li>
                                    ADMIN – Has access to add, edit and delete
                                    experiences, bookings and other staff
                                </li>

                                <li>
                                    USER – Has access to view experiences,
                                    bookings and reports
                                </li>
                            </ul>

                            
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            If you currently do not have admin access. In order to
                            edit another user, please request admin access from
                            your human resources department/company
                            administrator or alternatively ask someone who has
                            admin access. If you need to edit your information,
                            this can be done by:
                            <ul>
                                <li>
                                    Asking your manager who has admin access to
                                    make the change
                                </li>
                                <li>
                                    Click on the profile icon in the top right
                                    corner and edit your profile
                                </li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To manage a user:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>
                                    From the top right corner of the app, click
                                    on the cog wheel icon
                                </li>
                                <li>Select 'Users'</li>
                                <li>
                                    From the list, select the user you would
                                    like to view/edit'
                                </li>
                            </ol>
                            Note:
                            <ol>
                                <li>
                                    Only the user can re-set their password. To
                                    do this, view 'Re-setting password' section
                                    of the documentation page.
                                </li>
                                <li>
                                    You cannot delete a user, you can only
                                    disable them from accessing the app
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
      
        </>
    );
}
