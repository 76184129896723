import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  CardActionArea,
  CardActions,
  createTheme,
  Grid,
  List,
  ThemeProvider,
} from "@mui/material";
import { CustomButtonContainedGreen } from "Layout/CustomButton";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import MediaQuery from "react-responsive";
import { Link } from "react-router-dom";

const theme = createTheme({
  palette: {
    // @ts-ignore
    neutral: {
      main: "#5cb5b3",
      contrastText: "#5cb5b3",
    },
    other: {
      main: "#5cb5b3",
      contrastText: "#5cb5b3",
    },
  },
});
//DesktopStyle
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "auto",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 4,
  p: 4,
  overflowY: "scroll",
};

//LaptopStyle
const styleLaptop = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "67%",
  height: "90%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 4,
  p: 4,
  overflowY: "scroll",
};
//iPad

const styleIpad = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 4,
  p: 4,
  overflowY: "scroll",
};

//iphone

const styleIphone = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  maxHeight: "80%",
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 4,
  p: 4,
  overflowY: "scroll",
};

export default function AvailablePositions() {
  const [openJobOne, setJobOneOpen] = React.useState(false);
  const handleJobOneOpen = () => setJobOneOpen(true);
  const handleJobOneClose = () => setJobOneOpen(false);
  const [openJobTwo, setJobTwoOpen] = React.useState(false);
  const handleJobTwoOpen = () => setJobTwoOpen(true);
  const handleJobTwoClose = () => setJobTwoOpen(false);

  const DevJobList = [
    "Design, develop and manage backend APIs and services that make up the core of 7DAY",
    "Provide scalability to ensure the application can manage mass amounts of data and users",
    "Implement secure customer authentication",
    "Work closely with our Front-end team (React.js)",
    "Find ways to evolve and improve the way we develop, deploy and deliver software",
    "Revise, update, refactor and debug code",
    "Test and deploy applications and systems",
    "Use Git version control to manage codebase",
    "Have a voice and vision for the innovation of 7DAY",
  ];

  const techStack = ["React (TypeScript)", "Nest (TypeScript)", "AWS", "Jira"];

  const DevJobListFullStack = [
    "Supporting frontend and backend developers on new projects",
    "Refactoring and debugging the code",
    "Testing and deploying applications and systems",
    "Using Git version control to manage the codebase;and",
    "Handling any support enquiries, bugs and feature requests",
  ];

  const Benefits = [
    "Customise your workdays, to come in earlier or later than traditional schedules. The hours you work are determined by your own self-interests, not what the company dictates. ",
    "Work anywhere",
  ];
  const ExperienceListBackEnd = [
    "Tertiary qualification in Computer Science, Information Technology, Software Engineering or a related engineering discipline",
    "3+ years industry experience",
    "Proficiency with server side languages such as Python, Ruby, Java, PHP and .Net",
    "Familiarity with database technology such as MySQL, Oracle and MongoDB",
    "Excellent verbal communication skills",
    "Understanding of software development methodologies, software architecture and best practice",
    "Understanding of Test Driven Development (TDD) and automated testing best practices",
    "Knowledge of Git version control",
    "Familiarity with cloud computing platforms and tools, such as AWS",
    "Strong problem-solving and analytical skills",
  ];

  const ExperienceListFullStack = [
    "Tertiary qualification in Computer Science, Information Technology, Software Engineering or a related engineering discipline",
    "3+ years industry experience",
    "Proficiency with fundamental front end languages such as HTML, CSS and JavaScript",
    "Proficiency with server side languages such as Python, Ruby, Java, PHP and .Net",
    "Familiarity with JavaScript frameworks such as Angular JS, React and Amber",
    "Familiarity with database technology such as MySQL, Oracle and MongoDB",
    "Excellent verbal communication skills",
    "Understanding of software development methodologies, software architecture and best practice",
    "Understanding of Test Driven Development (TDD) and automated testing best practices",
    "Knowledge of Git version control",
    "Familiarity with cloud computing platforms and tools, such as AWS",
    "Strong problem-solving and analytical skills",
  ];

  const ExperienceListJuniorFullStack = [
    "Tertiary qualification in Computer Science, Information Technology, Software Engineering or a related engineering discipline",
    "6+ months industry experience",
    "Familiarity with JavaScript frameworks such as Angular JS, React and Amber",
    "Familiarity with database technology such as MySQL, Oracle and MongoDB",
    "Excellent verbal communication skills",
    "Understanding of software development methodologies, software architecture and best practice",
    "Understanding of Test Driven Development (TDD) and automated testing best practices",
    "Knowledge of Git version control",
    "Familiarity with cloud computing platforms and tools, such as AWS",
    "Strong problem-solving and analytical skills",
  ];

  const DesiredSkills = [
    "Experience with Node.js HTML, CSS and JavaScript",
    "Experience with REST APIs",
    "Knowledge of Relational Data Bases (SQL)",
    "Experience with server-side frameworks such as Nest",
    "Familiarity with Cloud/AWS",
    "Familiarity with design patterns and SOLID principles",
  ];

  return (
    <>
      {/* Large PC  Media Query */}
      <MediaQuery minWidth={1921}>
        <ThemeProvider theme={theme}>
          {" "}
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <Card sx={{ width: 350, margin: '5px' }}>
                            <CardActionArea onClick={handleJobOneOpen}>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                    >
                                        Senior Software Engineer - Backend
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Information technology
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Sydney
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Permanent/Full Time
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    // @ts-ignore
                                    color='neutral'
                                    variant='text'
                                    onClick={handleJobOneOpen}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card> */}

            <Card sx={{ width: 350, margin: "5px" }}>
              <CardActionArea onClick={handleJobTwoOpen}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Junior-mid Full Stack Developer
                  </Typography>
                  <Divider sx={{ paddingTop: "32px" }} />
                  <Typography variant="body2" color="text.secondary">
                    Information technology
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sydney
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Permanent/Full Time
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  // @ts-ignore
                  color="neutral"
                  variant="text"
                  onClick={handleJobTwoOpen}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* <Modal
                        open={openJobOne}
                        onClose={handleJobOneClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={style}>
                            <Typography
                                id='modal-modal-title'
                                variant='h6'
                                component='h2'
                                sx={{
                                    color: '#5cb5b3',
                                    fontSize: '30px',
                                    fontWeight: 900,
                                }}
                            >
                                Senior Software Engineer - Backend
                            </Typography>
                            <Divider />
                            <Typography
                                id='modal-modal-description'
                                sx={{ paddingTop: '25px' }}
                            >
                                In this role, it will be your responsibility for
                                setting up the backend infrastructure and
                                database for the online booking component for
                                the 7DAY Application. You will:
                            </Typography>
                            <List>
                                {DevJobList.map((job) => {
                                    return (
                                        <ul key={job}>
                                            <li>{job}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                Why join 7DAY?
                            </Typography>
                            <Typography sx={{ paddingTop: '25px' }}>
                                As an employee of 7DAY, you are free to:
                            </Typography>
                            <List>
                                {Benefits.map((benefit) => {
                                    return (
                                        <ul key={benefit}>
                                            <li>{benefit}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                What experience do you need?
                            </Typography>
                            <List sx={{ paddingTop: '25px' }}>
                                {ExperienceListBackEnd.map((experience) => {
                                    return (
                                        <ul key={experience}>
                                            <li>{experience}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Grid sx={{ paddingTop: '50px' }}>
                                <Link
                                    style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}
                                    to={{
                                        pathname:
                                            'https://www.seek.com.au/job/66239746',
                                    }}
                                    target='blank'
                                >
                                    <CustomButtonContainedGreen>
                                        Apply Now
                                    </CustomButtonContainedGreen>
                                </Link>
                            </Grid>
                        </Box>
                    </Modal> */}
          <Modal
            open={openJobTwo}
            onClose={handleJobTwoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#5cb5b3",
                  fontSize: "30px",
                  fontWeight: 900,
                }}
              >
                Junior-mid Full Stack Developer
              </Typography>
              <Divider />
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                In this supporting role, it will be your responsibility to
                support the senior developers for the frontend design and
                implementation as well as the backend infrastructure for the
                online booking component of the 7DAY Application. You will
                contribute to the design, architecture and implementation of
                projects using:
              </Typography>
              <List>
                {techStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                Your primary responsibilities will be:
              </Typography>
              <List>
                {DevJobListFullStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Why join 7DAY?
              </Typography>
              <Typography sx={{ paddingTop: "25px" }}>
                As an employee of 7DAY, you are free to:
              </Typography>
              <List>
                {Benefits.map((benefit) => {
                  return (
                    <ul key={benefit}>
                      <li>{benefit}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                What experience do you need?
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {ExperienceListJuniorFullStack.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Desired Skills
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {DesiredSkills.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Grid sx={{ paddingTop: "50px" }}>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={"https://www.seek.com.au/job/73110873"}
                  target='_blank'
                >
                  <CustomButtonContainedGreen>
                    Apply Now
                  </CustomButtonContainedGreen>
                </Link>
              </Grid>
            </Box>
          </Modal>
        </ThemeProvider>
      </MediaQuery>
      {/* Laptop Media Query */}
      <MediaQuery minWidth={1400} maxWidth={1920}>
        <ThemeProvider theme={theme}>
          {" "}
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <Card sx={{ width: 350, margin: '5px' }}>
                            <CardActionArea onClick={handleJobOneOpen}>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                    >
                                        Senior Software Engineer - Backend
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Information technology
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Sydney
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Permanent/Full Time
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    // @ts-ignore
                                    color='neutral'
                                    variant='text'
                                    onClick={handleJobOneOpen}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card> */}

            <Card sx={{ width: 350, margin: "5px" }}>
              <CardActionArea onClick={handleJobTwoOpen}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Junior-mid Full Stack Developer
                  </Typography>
                  <Divider sx={{ paddingTop: "32px" }} />
                  <Typography variant="body2" color="text.secondary">
                    Information technology
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sydney
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Permanent/Full Time
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  // @ts-ignore
                  color="neutral"
                  variant="text"
                  onClick={handleJobTwoOpen}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* <Modal
                        open={openJobOne}
                        onClose={handleJobOneClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={styleLaptop}>
                            <Typography
                                id='modal-modal-title'
                                variant='h6'
                                component='h2'
                                sx={{
                                    color: '#5cb5b3',
                                    fontSize: '30px',
                                    fontWeight: 900,
                                }}
                            >
                                Senior Software Engineer - Backend
                            </Typography>
                            <Divider />
                            <Typography
                                id='modal-modal-description'
                                sx={{ paddingTop: '25px' }}
                            >
                                In this role, it will be your responsibility for
                                setting up the backend infrastructure and
                                database for the online booking component for
                                the 7DAY Application. You will:
                            </Typography>
                            <List>
                                {DevJobList.map((job) => {
                                    return (
                                        <ul key={job}>
                                            <li>{job}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                Why join 7DAY?
                            </Typography>
                            <Typography sx={{ paddingTop: '25px' }}>
                                As an employee of 7DAY, you are free to:
                            </Typography>
                            <List>
                                {Benefits.map((benefit) => {
                                    return (
                                        <ul key={benefit}>
                                            <li>{benefit}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                What experience do you need?
                            </Typography>
                            <List sx={{ paddingTop: '25px' }}>
                                {ExperienceListBackEnd.map((experience) => {
                                    return (
                                        <ul key={experience}>
                                            <li>{experience}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Grid sx={{ paddingTop: '50px' }}>
                                <Link
                                    style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}
                                    to={{
                                        pathname:
                                            'https://www.seek.com.au/job/66239746',
                                    }}
                                    target='blank'
                                >
                                    <CustomButtonContainedGreen>
                                        Apply Now
                                    </CustomButtonContainedGreen>
                                </Link>
                            </Grid>
                        </Box>
                    </Modal> */}
          <Modal
            open={openJobTwo}
            onClose={handleJobTwoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleLaptop}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#5cb5b3",
                  fontSize: "30px",
                  fontWeight: 900,
                }}
              >
                Junior-mid Full Stack Developer
              </Typography>
              <Divider />
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                In this supporting role, it will be your responsibility to
                support the senior developers for the frontend design and
                implementation as well as the backend infrastructure for the
                online booking component of the 7DAY Application. You will
                contribute to the design, architecture and implementation of
                projects using:
              </Typography>
              <List>
                {techStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                Your primary responsibilities will be:
              </Typography>
              <List>
                {DevJobListFullStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Why join 7DAY?
              </Typography>
              <Typography sx={{ paddingTop: "25px" }}>
                As an employee of 7DAY, you are free to:
              </Typography>
              <List>
                {Benefits.map((benefit) => {
                  return (
                    <ul key={benefit}>
                      <li>{benefit}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                What experience do you need?
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {ExperienceListJuniorFullStack.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Desired Skills
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {DesiredSkills.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Grid sx={{ paddingTop: "50px" }}>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={"https://www.seek.com.au/job/73110873"}
                  target="_blank"
                >
                  <CustomButtonContainedGreen>
                    Apply Now
                  </CustomButtonContainedGreen>
                </Link>
              </Grid>
            </Box>
          </Modal>
        </ThemeProvider>
      </MediaQuery>
      {/* Tablet Media Query */}
      <MediaQuery minWidth={768} maxWidth={1396}>
        <ThemeProvider theme={theme}>
          {" "}
          <Grid
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* <Card sx={{ width: 350, margin: '5px' }}>
                            <CardActionArea onClick={handleJobOneOpen}>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                    >
                                        Senior Software Engineer - Backend
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Information technology
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Sydney
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Permanent/Full Time
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    // @ts-ignore
                                    color='neutral'
                                    variant='text'
                                    onClick={handleJobOneOpen}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card> */}

            <Card sx={{ width: 350, margin: "5px" }}>
              <CardActionArea onClick={handleJobTwoOpen}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Junior-mid Full Stack Developer
                  </Typography>
                  <Divider sx={{ paddingTop: "32px" }} />
                  <Typography variant="body2" color="text.secondary">
                    Information technology
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sydney
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Permanent/Full Time
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  // @ts-ignore
                  color="neutral"
                  variant="text"
                  onClick={handleJobTwoOpen}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          {/* <Modal
                        open={openJobOne}
                        onClose={handleJobOneClose}
                        aria-labelledby='modal-modal-title'
                        aria-describedby='modal-modal-description'
                    >
                        <Box sx={styleIpad}>
                            <Typography
                                id='modal-modal-title'
                                variant='h6'
                                component='h2'
                                sx={{
                                    color: '#5cb5b3',
                                    fontSize: '30px',
                                    fontWeight: 900,
                                }}
                            >
                                Senior Software Engineer - Backend
                            </Typography>
                            <Divider />
                            <Typography
                                id='modal-modal-description'
                                sx={{ paddingTop: '25px' }}
                            >
                                In this role, it will be your responsibility for
                                setting up the backend infrastructure and
                                database for the online booking component for
                                the 7DAY Application. You will:
                            </Typography>
                            <List>
                                {DevJobList.map((job) => {
                                    return (
                                        <ul key={job}>
                                            <li>{job}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                Why join 7DAY?
                            </Typography>
                            <Typography sx={{ paddingTop: '25px' }}>
                                As an employee of 7DAY, you are free to:
                            </Typography>
                            <List>
                                {Benefits.map((benefit) => {
                                    return (
                                        <ul key={benefit}>
                                            <li>{benefit}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Typography
                                sx={{
                                    color: '#5cb5b3',
                                    paddingTop: '25px',
                                    fontSize: '20px',
                                    fontWeight: 900,
                                }}
                            >
                                What experience do you need?
                            </Typography>
                            <List sx={{ paddingTop: '25px' }}>
                                {ExperienceListBackEnd.map((experience) => {
                                    return (
                                        <ul key={experience}>
                                            <li>{experience}</li>
                                        </ul>
                                    );
                                })}
                            </List>
                            <Grid sx={{ paddingTop: '50px' }}>
                                <Link
                                    style={{
                                        color: 'white',
                                        textDecoration: 'none',
                                    }}
                                    to={{
                                        pathname:
                                            'https://www.seek.com.au/job/66239746',
                                    }}
                                    target='blank'
                                >
                                    <CustomButtonContainedGreen>
                                        Apply Now
                                    </CustomButtonContainedGreen>
                                </Link>
                            </Grid>
                        </Box>
                    </Modal> */}
          <Modal
            open={openJobTwo}
            onClose={handleJobTwoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleIpad}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#5cb5b3",
                  fontSize: "30px",
                  fontWeight: 900,
                }}
              >
                Junior-mid Full Stack Developer
              </Typography>
              <Divider />
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                In this supporting role, it will be your responsibility to
                support the senior developers for the frontend design and
                implementation as well as the backend infrastructure for the
                online booking component of the 7DAY Application. You will
                contribute to the design, architecture and implementation of
                projects using:
              </Typography>
              <List>
                {techStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                Your primary responsibilities will be:
              </Typography>
              <List>
                {DevJobListFullStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Why join 7DAY?
              </Typography>
              <Typography sx={{ paddingTop: "25px" }}>
                As an employee of 7DAY, you are free to:
              </Typography>
              <List>
                {Benefits.map((benefit) => {
                  return (
                    <ul key={benefit}>
                      <li>{benefit}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                What experience do you need?
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {ExperienceListJuniorFullStack.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Desired Skills
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {DesiredSkills.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Grid sx={{ paddingTop: "50px" }}>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={"https://www.seek.com.au/job/73110873"}
                  target='_blank'
                >
                  <CustomButtonContainedGreen>
                    Apply Now
                  </CustomButtonContainedGreen>
                </Link>
              </Grid>
            </Box>
          </Modal>
        </ThemeProvider>
      </MediaQuery>
      {/* IPhone Media Query */}
      <MediaQuery maxWidth={767}>
        <ThemeProvider theme={theme}>
          {" "}
          <Grid
            sx={{
              width: "100vm",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* <Card sx={{ width: 350, margin: '5px' }}>
                            <CardActionArea onClick={handleJobOneOpen}>
                                <CardContent>
                                    <Typography
                                        gutterBottom
                                        variant='h5'
                                        component='div'
                                    >
                                        Senior Software Engineer - Backend
                                    </Typography>
                                    <Divider />
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Information technology
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Sydney
                                    </Typography>
                                    <Typography
                                        variant='body2'
                                        color='text.secondary'
                                    >
                                        Permanent/Full Time
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button
                                    // @ts-ignore
                                    color='neutral'
                                    variant='text'
                                    onClick={handleJobOneOpen}
                                >
                                    View Details
                                </Button>
                            </CardActions>
                        </Card> */}

            <Card sx={{ width: 350, margin: "5px" }}>
              <CardActionArea onClick={handleJobTwoOpen}>
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    Junior-mid Full Stack Developer
                  </Typography>
                  <Divider sx={{ paddingTop: "32px" }} />
                  <Typography variant="body2" color="text.secondary">
                    Information technology
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Sydney
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Permanent/Full Time
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button
                  // @ts-ignore
                  color="neutral"
                  variant="text"
                  onClick={handleJobTwoOpen}
                >
                  View Details
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Modal
            open={openJobOne}
            onClose={handleJobOneClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleIphone}>
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  color: "#5cb5b3",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Senior Software Engineer - Backend
              </Typography>
              <Divider />
              <Typography sx={{ paddingTop: "15px", fontSize: "15px" }}>
                In this supporting role, it will be your responsibility to
                support the senior developers for the frontend design and
                implementation as well as the backend infrastructure for the
                online booking component of the 7DAY Application. You will
                contribute to the design, architecture and implementation of
                projects using:
              </Typography>
              <List>
                {DevJobList.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "5px",
                  fontSize: "18px",
                  fontWeight: 900,
                }}
              >
                Why join 7DAY?
              </Typography>
              <Typography sx={{ paddingTop: "5px", fontSize: "15px" }}>
                As an employee of 7DAY, you are free to:
              </Typography>
              <List>
                {Benefits.map((benefit) => {
                  return (
                    <ul key={benefit}>
                      <li>{benefit}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "5px",
                  fontSize: "18px",
                  fontWeight: 900,
                }}
              >
                What experience do you need?
              </Typography>
              <List sx={{ paddingTop: "5px", fontSize: "15px" }}>
                {ExperienceListBackEnd.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Desired Skills
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {DesiredSkills.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Grid sx={{ paddingTop: "10px" }}>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={"https://www.seek.com.au/job/73110873"}
                  target='_blank'
                >
                  <CustomButtonContainedGreen>
                    Apply Now
                  </CustomButtonContainedGreen>
                </Link>
              </Grid>
            </Box>
          </Modal>
          <Modal
            open={openJobTwo}
            onClose={handleJobTwoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={styleIphone}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  color: "#5cb5b3",
                  fontSize: "30px",
                  fontWeight: 900,
                }}
              >
                Junior-mid Full Stack Developer
              </Typography>
              <Divider />
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                In this supporting role, it will be your responsibility to
                support the senior developers for the frontend design and
                implementation as well as the backend infrastructure for the
                online booking component of the 7DAY Application. You will
                contribute to the design, architecture and implementation of
                projects using:
              </Typography>
              <List>
                {techStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                id="modal-modal-description"
                sx={{ paddingTop: "25px" }}
              >
                Your primary responsibilities will be:
              </Typography>
              <List>
                {DevJobListFullStack.map((job) => {
                  return (
                    <ul key={job}>
                      <li>{job}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Why join 7DAY?
              </Typography>
              <Typography sx={{ paddingTop: "25px" }}>
                As an employee of 7DAY, you are free to:
              </Typography>
              <List>
                {Benefits.map((benefit) => {
                  return (
                    <ul key={benefit}>
                      <li>{benefit}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                What experience do you need?
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {ExperienceListJuniorFullStack.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Typography
                sx={{
                  color: "#5cb5b3",
                  paddingTop: "25px",
                  fontSize: "20px",
                  fontWeight: 900,
                }}
              >
                Desired Skills
              </Typography>
              <List sx={{ paddingTop: "25px" }}>
                {DesiredSkills.map((experience) => {
                  return (
                    <ul key={experience}>
                      <li>{experience}</li>
                    </ul>
                  );
                })}
              </List>
              <Grid sx={{ paddingTop: "50px" }}>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={"https://www.seek.com.au/job/73110873"}
                  target='_blank'
                >
                  <CustomButtonContainedGreen>
                    Apply Now
                  </CustomButtonContainedGreen>
                </Link>
              </Grid>
            </Box>
          </Modal>
        </ThemeProvider>
      </MediaQuery>
    </>
  );
}
