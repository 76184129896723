import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Container,
  createTheme,
  Grid,
  InputAdornment,
  TextField,
  ThemeProvider,
} from "@mui/material";
import { useMediaQuery } from "Layout/MobileOptimiseHook";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { CustomRoundButton } from "Layout/CustomButton";
import PlaceIcon from "@mui/icons-material/Place";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";

// const { REACT_APP_SITE_KEY } = process.env;

const SERVICE_ID = "service_iuq2c7c";
const TEMPLATE_ID = "template_8khflwo";
const USER_ID = "BYnD6lRrcDdjsaoXt";

const theme = createTheme({
  palette: {
    // @ts-ignore
    neutral: {
      main: "#fff",
      contrastText: "#5cb5b3",
    },
    other: {
      main: "#5cb5b3",
      contrastText: "#5cb5b3",
    },
  },
});

export const ContactContent = () => {
  const isMobile = useMediaQuery("(max-width: 770px)");
  const isIpad = useMediaQuery("(max-width: 1200px)");
  const isLaptop = window.innerWidth < 2560 && window.innerWidth > 1030;
  const [submitted, setSubmitted] = useState(false);
  // const captchaRef = useRef(null);
  // const token = captchaRef
  console.log(window.innerWidth);
  const [values, setValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    comment: "",
  });

  //Validation Check
  const validationCheck = Object.values(values).includes("");

  const handleFirstNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      firstName: event.target.value,
    }));
  };

  const handleLastNameInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      lastName: event.target.value,
    }));
  };

  const handleEmailInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      email: event.target.value,
    }));
  };

  const handleCommentInputChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      comment: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    setSubmitted(true);
    e.preventDefault();
    // const token = captchaRef.current.getValue();
    // console.log(token)
    // captchaRef.current.reset();
    emailjs
      .sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID)
      .then((result) => {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Your message has been sent, we will respond shortly.",
          confirmButtonColor: "#5cb5b3",
        });
      });
    e.target.reset();
  };
//   const navigate = useNavigate();
  const openMaps = () => {
    window.open("https://goo.gl/maps/168ZM835dShXM1An6", "_blank");
    // window.location.href =
    // navigate('https://goo.gl/maps/168ZM835dShXM1An6')
  };

  return (
    <ThemeProvider theme={theme}>
      <Box
        id="contact"
        sx={{
          width: "100%",
          height: isMobile ? "100vh" : "100vh",
          backgroundImage: isMobile
            ? "linear-gradient(180deg,#5cb5b3 80%,white 23%)"
            : isLaptop
            ? "linear-gradient(180deg,#5cb5b3 56%,white 23%)"
            : "linear-gradient(180deg,#5cb5b3 50%,white 23%)",
        }}
      >
        <Container
          fixed
          maxWidth={isLaptop ? "lg" : "xl"}
          sx={{
            height: isMobile ? "100%" : "100%",
            paddingTop: isMobile ? "110px" : "190px",
            paddingBottom: "45px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {" "}
          <Grid
            sx={{
              width: "100%",
              height: "25%",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              component={isMobile ? "h4" : isLaptop ? "h2" : "h1"}
              variant={isMobile ? "h4" : isLaptop ? "h2" : "h1"}
              color="#fff"
              sx={{
                fontFamily: "Open Sans",
                // textAlign: 'left',
                textTransform: "uppercase",
                letterSpacing: isMobile ? "" : ".4rem",
              }}
            >
              {" "}
              Get in touch
            </Typography>
          </Grid>
          <Grid
            sx={{
              height: "100%",
              width: "100%",
              paddingTop: isMobile || isLaptop ? "30px" : "0px",
              // paddingBottom: '50px',
              display: "flex",
              flexDirection: "column",
              alignSelf: "center",
            }}
          >
            <Grid
              sx={{
                width: "100%",
                height: isMobile ? "100%" : "30%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                // backgroundColor: 'red',
              }}
            >
              <Grid
                sx={{
                  width: isMobile ? "100%" : "33.3%",
                  height: "100%",
                  textAlign: "center",
                  // backgroundColor: 'blue',
                }}
              >
                <CustomRoundButton onClick={openMaps}>
                  <PlaceIcon
                    sx={{
                      color: "white",
                      fontSize: isLaptop ? "30px" : "50px",
                    }}
                    fontSize="large"
                  />
                </CustomRoundButton>

                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "18px" : "30px",
                    textTransform: "uppercase",
                    // fontWeight: 900,
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Address
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "14px" : "18px",
                    // fontWeight: 500,
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Headquarters
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "10px" : "14px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Level 2, 120 Sussex St
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "10px" : "14px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Sydney, NSW 2000
                </Typography>
              </Grid>
              <Grid
                sx={{
                  height: isMobile ? "100%" : "100%",
                  width: isMobile ? "100%" : "33.3%",
                  textAlign: "center",
                  fontFamily: "Open Sans",
                  paddingTop: isMobile ? "30px" : "0px",
                  paddingBottom: isMobile ? "30px" : "0px",
                  // backgroundColor: 'green',
                }}
              >
                <Link
                  style={{ color: "white" }}
                  to="#"
                  onClick={() =>
                    // @ts-ignore
                    (window.location = "tel://+61417139641")
                  }
                >
                  {" "}
                  <CustomRoundButton>
                    <LocalPhoneIcon
                      sx={{
                        color: "white",
                        fontSize: isLaptop ? "30px" : "50px",
                      }}
                      fontSize="large"
                    />
                  </CustomRoundButton>{" "}
                </Link>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "18px" : "30px",
                    textTransform: "uppercase",
                    // fontWeight: 900,
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Phone
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    // fontWeight: 500,
                    fontSize: isMobile || isLaptop ? "14px" : "18px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  General Enquiries
                </Typography>
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to="#"
                  onClick={() =>
                    // @ts-ignore
                    (window.location = "tel://+61417139641")
                  }
                >
                  <Typography
                    sx={{
                      color: "white",
                      fontSize: isMobile || isLaptop ? "10px" : "14px",
                      fontFamily: "Open Sans",
                    }}
                  >
                    {" "}
                    0417 139 641
                  </Typography>
                </Link>
              </Grid>
              <Grid
                sx={{
                  width: isMobile ? "100%" : "33.3%",
                  height: "100%",
                  textAlign: "center",
                  paddingTop: isMobile ? "30px" : "0px",
                }}
              >
                <Link
                  style={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to="#"
                  // @ts-ignore
                  onClick={() =>
                    // @ts-ignore
                    (window.location = "mailto: info@7-day.com")
                  }
                >
                  <CustomRoundButton>
                    <MailOutlineIcon
                      sx={{
                        color: "white",
                        fontSize: isLaptop ? "30px" : "50px",
                      }}
                      fontSize="large"
                    />
                  </CustomRoundButton>
                </Link>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "18px" : "30px",
                    textTransform: "uppercase",
                    // fontWeight: 900,
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  Email
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    // fontWeight: 500,
                    fontSize: isMobile || isLaptop ? "14px" : "18px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  General Enquiries
                </Typography>
                <Typography
                  sx={{
                    color: "white",
                    fontSize: isMobile || isLaptop ? "10px" : "14px",
                    fontFamily: "Open Sans",
                  }}
                >
                  {" "}
                  info@7-day.com
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                width: "100%",
                height: isMobile ? "100%" : "30%",
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                justifyContent: "center",
                // backgroundColor: 'blue',
                marginTop: isMobile || isLaptop ? "30px" : "0px",
              }}
            >
              <Grid
                sx={{
                  height: "100%",
                  width: isMobile ? "100%" : "50%",
                  display: "flex",
                  flexDirection: isMobile ? "row" : "column",
                }}
              >
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                  }}
                >
                  <Grid
                    sx={{
                      width: isIpad ? "19%" : "25%",
                      display: { xs: "none", md: "flex" },
                    }}
                  ></Grid>
                  <Grid
                    sx={{
                      width: isMobile ? "100%" : "75%",
                      paddingRight: isIpad || isMobile ? "0px" : "80px",
                      paddingTop: "0px",
                    }}
                  >
                    {" "}
                    <Grid>
                      <Typography
                        sx={{
                          color: "#5cb5b3",
                          fontWeight: 500,
                          fontSize: isLaptop ? "20px" : "30px",
                          paddingTop: "50px",
                          fontFamily: "Open Sans",
                        }}
                      >
                        Message Us
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        sx={{
                          color: "#787878",
                          paddingTop: "30px",
                          fontFamily: "Open Sans",
                          fontSize: isLaptop ? "16px" : "18px",
                        }}
                      >
                        If you wish to be considered for employment at 7DAY,
                        please do not send a message here - instead, please
                        apply through{" "}
                        <Link
                          style={{
                            color: "#5cb5b3",
                            textDecoration: "none",
                          }}
                          target="_blank"
                          to={"https://www.seek.com.au"}
                        >
                          SEEK
                        </Link>{" "}
                        and our Human Resources Department will contact you
                        after they review your submitted application.
                      </Typography>
                    </Grid>{" "}
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                sx={{
                  height: "100%",
                  width: isMobile ? "100%" : "50%",
                  paddingTop: "50px",
                  paddingBottom: "50px",
                  // backgroundColor: 'blue'
                }}
              >
                <Grid
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: isMobile ? "column" : "row",
                    paddingBottom: isMobile || isLaptop ? "30px" : "0px",
                  }}
                >
                  <Grid
                    sx={{
                      width: isMobile ? "100%" : "75%",
                      paddingLeft: isIpad || isMobile ? "0px" : "80px",
                    }}
                  >
                    <form onSubmit={handleSubmit}>
                      <Grid
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                          // backgroundColor: 'red',
                        }}
                      >
                        <TextField
                          label="First"
                          name="user_first"
                          id="outlined-start-adornment"
                          fullWidth
                          // value={values.firstName}
                          onChange={handleFirstNameInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />

                        <TextField
                          label="Last"
                          name="user_last"
                          id="outlined-start-adornment"
                          fullWidth
                          sx={{ paddingLeft: "10px" }}
                          // value={values.lastName}
                          onChange={handleLastNameInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />
                      </Grid>

                      <Grid
                        sx={{
                          paddingTop: "20px",
                          width: "100%",
                          // backgroundColor: 'blue',
                        }}
                      >
                        <TextField
                          label="Email"
                          name="user_email"
                          id="outlined-start-adornment"
                          // value={values.email}
                          fullWidth
                          onChange={handleEmailInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid sx={{ paddingTop: "20px" }}>
                        <TextField
                          label="Message"
                          name="user_message"
                          id="outlined-start-adornment"
                          // value={values.comment}
                          fullWidth
                          onChange={handleCommentInputChange}
                          multiline
                          rows={4}
                          maxRows={4}
                          inputProps={{
                            maxLength: 150,
                          }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start"></InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      {/* <ReCAPTCHA
                                            ref={captchaRef}
                                            sitekey={
                                                process.env.REACT_APP_SITE_KEY
                                            }
                                        /> */}
                      {validationCheck !== true ? (
                        <Button
                          sx={{
                            marginTop: "20px",
                            marginLeft: "10px",
                            fontFamily: "Open Sans",
                          }}
                          // @ts-ignore
                          color="neutral"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          disabled
                          sx={{
                            marginTop: "20px",
                            marginLeft: "10px",
                            fontFamily: "Open Sans",
                          }}
                          // @ts-ignore
                          color="neutral"
                          type="submit"
                          variant="contained"
                        >
                          Submit
                        </Button>
                      )}
                    </form>
                  </Grid>
                  <Grid
                    sx={{
                      width: isIpad ? "19%" : "25%",
                      paddingRight: "80px",
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    <Grid></Grid>{" "}
                  </Grid>
                </Grid>{" "}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ThemeProvider>
  );
};
