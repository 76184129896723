import './App.css';
import { hot } from 'react-hot-loader/root';
import { BrowserRouter } from 'react-router-dom';
import CustomRouter from '../shared/custom-router';
import React, { useEffect } from 'react';
import { updateComponentName } from './slice/componentSelectedSlice';
import { useDispatch } from 'react-redux';

function App() {
    const dispatch = useDispatch();
    useEffect(() => {
        setComponentName()
    }, []);


    const setComponentName = () => {
        dispatch(updateComponentName({ value: 'home' }));
    };


    return (
        <BrowserRouter>
            <CustomRouter />
        </BrowserRouter>
    );
}

export default hot(App);
