import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, useMediaQuery } from '@mui/material';

export const CareersHeroContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');

    return (
        <Box
            id='other'
            sx={{
                backgroundColor: 'white',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                height: isIpad ? '25vh' : '20vh',
                width: 'auto',
                padding: isMobile || isIpad ? '20px' : '160px',
            }}
        >
            <Typography
                sx={{
                    color: '#5cb5b3',
                    fontSize: isMobile ? '15px' : isIpad ? '21px' : '31px',
                    // textTransform: 'uppercase',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    // letterSpacing: isMobile || isIpad ? '' : '.2rem',
                    fontStyle: 'italic',
                }}
            >
                "Offices should be designed to maximize energy and interactions,
                not for isolation and status"
            </Typography>
            <Typography
                sx={{
                    color: '#5cb5b3',
                    fontSize: isMobile ? '12px' : isIpad ? '12px' : '16px',
                    // textTransform: 'uppercase',
                    fontStyle: 'italic',
                    fontFamily: 'Open Sans',
                }}
            >
                – Eric Schmidt, How Google Works
            </Typography>
        </Box>
    );
};
