import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useMediaQuery } from '@mui/material';

const theme = createTheme({
    palette: {
        // @ts-ignore
        neutral: {
            main: '#fff',
            contrastText: '#5cb5b3',
        },
        green: {
          main: '#5cb5b3',
          contrastText: '#fff',
      },
        other: {
            main: '#5cb5b3',
            contrastText: '#5cb5b3',
        },
        roundButton: {
            main: '#addad9',
            contrastText: '#5cb5b3',
        },
    },
});

export function CustomButtonContained({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <Button
                // @ts-ignore
                color='neutral'
                variant='contained'
            >
                {children}
            </Button>
        </ThemeProvider>
    );
}

export function CustomButtonContainedGreen({ children }) {
  return (
      <ThemeProvider theme={theme}>
          <Button
              // @ts-ignore
              color='green'
              variant='contained'
          >
              {children}
          </Button>
      </ThemeProvider>
  );
}
export function CustomButtonText({ children }) {
    return (
        <ThemeProvider theme={theme}>
            <Button
                // @ts-ignore
                color='other'
                variant='text'
            >
                {children}
            </Button>
        </ThemeProvider>
    );
}

export function CustomRoundButton({ children,...props }) {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isLaptop = window.innerWidth <2560  && window.innerWidth > 1030
    return (
        <ThemeProvider theme={theme}>
            <Button // @ts-ignore
                color='roundButton'
                variant='contained'
                sx={{ width:  isLaptop || isMobile? '60px':'100px', height: isLaptop|| isMobile ? '60px':'100px', borderRadius: '50%', }}
                {...props}
            >
                {children}
            </Button>
        </ThemeProvider>
    );
}
