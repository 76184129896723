import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import MediaQuery from 'react-responsive';
import { Link } from 'react-scroll';
import { CustomButtonContained } from 'Layout/CustomButton';

export const VisionContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = window.innerWidth < 2560 && window.innerWidth > 1030;

    return (
        <Box
            id='vision'
            sx={{
                backgroundImage: isMobile|| isLaptop
                    ? 'linear-gradient(180deg,white 45%,#f6f8f9 23%)'
                    : isIpad
                    ? 'linear-gradient(180deg,white 35%,#f6f8f9 23%)'
                    : 'linear-gradient(180deg,white 50%,#f6f8f9 23%)',
                width: isIpad ? '100%' : '100%',
                // maxWidth: window.innerWidth <2560  && window.innerWidth > 1030 ?  '1500px': 'auto',
                height: isIpad || isMobile ? '100vh' : 'auto',
            }}
        >
            <Container
                fixed
                maxWidth={isLaptop ? 'lg' : 'xl'}
                sx={{
                    height: '100%',
                    paddingTop: isMobile ? '110px' : '190px',
                    paddingBottom: '45px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: '25%',
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            component={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            variant={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            {' '}
                            About Us
                        </Typography>
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            variant='h6'
                            component='h2'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            We are offering businesses the ultimate work perk!
                        </Typography>
                    </Grid>
                </Grid>
                {/* {isIpad ? null : ( */}
                <Grid
                    sx={{
                        width: '100%',
                        height: '25%',
                        paddingTop: isMobile ? '0px' : '50px',
                    }}
                >
                    <Grid>
                        <img
                            alt='boat'
                            src='/imgs/sitegraphics/boatsunset.jpg'
                            style={{
                                width:
                                     isLaptop
                                        ? '1140px'
                                        : '100%',
                                        minWidth: isMobile ? '350px': 'auto',
                                        maxWidth: isMobile ? '400px': 'auto',
                                height:
                                    isMobile || isIpad
                                        ? 'auto'
                                        : isLaptop
                                        ? 'auto'
                                        : '550px',
                            }}
                        />
                    </Grid>
                </Grid>
                {/* )} */}

                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        // backgroundColor: 'purple',
                        paddingTop: isMobile ? '5px' : '50px',
                        paddingBottom: '50px',
                    }}
                >
                    {' '}
                    {isMobile ? null : (
                        <Grid
                            sx={{
                                width: isIpad ? '40%' : 'auto',
                                height: '100%',
                                // backgroundColor: 'purple',
                            }}
                        >
                            <Grid
                                sx={{
                                    height: isIpad ? '25%' : '40%',
                                    width: isIpad ? '100%' : '60%',
                                    paddingRight: '0px',
                                    // backgroundColor: 'purple',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize:
                                            isIpad || isLaptop
                                                ? '30px'
                                                : '40px',
                                        color: '#5cb5b3',
                                        fontFamily: 'Open Sans',
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    The power of culture makes a difference
                                </Typography>
                            </Grid>

                            <Grid
                                sx={{
                                    height: isIpad ? '25%' : '60%',
                                    textAlign: 'left',
                                    paddingTop: isIpad ? '0px' : '30px',
                                }}
                            >
                                <Link
                                    to={'contact'}
                                    spy={true}
                                    smooth={true}
                                    offset={100}
                                    duration={2000}
                                    delay={350}
                                >
                                    {' '}
                                    <CustomButtonContained>
                                        Get In Touch
                                    </CustomButtonContained>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : isIpad ? '60%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: 'green',
                        }}
                    >
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Gone are the days where jobs are 9-5 and based
                                in high-density environments. Our online
                                marketplace connects people who want to rent out
                                a property, boat, vehicle or experience to
                                businesses who are looking for the ultimate work
                                perk for their employees.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                As the world continues to change, the 7DAY app
                                will help businesses meet modern world
                                expectations when it comes to work-life balance
                                for their employees.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {' '}
                                Change your 9-5 structure today! By providing
                                these culture captivating opportunities and
                                flexible locations it will boost team morale and
                                help your business stand out from the crowd!
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
