import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';
import DocumentationComponentPage from './HelpDocs/DocumentationComponent';

export const HelpContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = window.innerWidth < 2560 && window.innerWidth > 1030;
    return (
        <Box sx={{ backgroundColor: '#fff' }}>
            <Container
                id='product'
                fixed
                maxWidth={isLaptop ? 'lg' : 'xl'}
                sx={{
                    height: '100%',
                    paddingTop: isMobile ? '130px' : '190px',
                    paddingBottom: '45px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: 'auto',
                    }}
                >
                    <Typography
                        component={isMobile ? 'h4' : isLaptop ? 'h3' : 'h2'}
                        variant={isMobile ? 'h4' : isLaptop ? 'h3' : 'h2'}
                        color='#787878'
                        sx={{
                            fontFamily: 'Open Sans',
                            // textAlign: 'left',
                            textTransform: 'uppercase',
                        }}
                    >
                        {' '}
                        Help Docs
                    </Typography>
                </Grid>
                <Grid
                    sx={{
                        width: '100%',
                        height: 'auto',
                        paddingTop: '30px',
                    }}
                >
                    {' '}
                    <DocumentationComponentPage />
                </Grid>
            </Container>
        </Box>
    );
};
