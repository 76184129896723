import React, { useEffect } from 'react';
import { updateComponentName } from 'app/slice/componentSelectedSlice';
import { useDispatch } from 'react-redux';
import { HelpContent } from 'feature/components/HelpContent/HelpContent';

export const HelpPage = () => {
    const dispatch = useDispatch();
    //to know what component was clicked

    useEffect(() => {
 
        dispatch(updateComponentName({ value: 'help' }));
    
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (

          <HelpContent/>

    );
};
