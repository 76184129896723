import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, useMediaQuery } from '@mui/material';

export const VisionHeroContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    return (
        <Box
            id='other'
            sx={{
                backgroundColor: '#f6f8f9',
                display: 'flex',
                flexDirection: 'column',
                alignContent: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                height: isIpad ? '25vh' : '20vh',
                width: 'auto',
                padding:  isIpad ? '20px' : '160px',
            }}
        >
            <Typography
                sx={{
                    color: '#5cb5b3',
                    fontSize: isMobile ? '15px' : isIpad ? '21px' : '31px',
                    textTransform: 'uppercase',
                    fontFamily: 'Open Sans',
                    textAlign: 'center',
                    letterSpacing: isMobile || isIpad ? '' : '.2rem',
                }}
            >
                We are challenging the business world by changing the way Work
                works!
            </Typography>
        </Box>
    );
};
