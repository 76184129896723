import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-router-dom';

export const ProductContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = window.innerWidth < 2560 && window.innerWidth > 1030;
    return (
        <Box sx={{ backgroundColor: '#fff' }}>
            <Container
                id='product'
                fixed
                maxWidth={isLaptop ? 'lg' : 'xl'}
                sx={{
                    height: '100%',
                    paddingTop: isMobile ? '130px' : '190px',
                    paddingBottom: '45px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: '25%',
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            component={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            variant={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            {' '}
                            Features
                        </Typography>
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                            // backgroundColor: 'red',
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid
                            sx={{
                                width: 'auto',
                                margin: '0px 0px 30px 0px',
                                // backgroundColor: 'yellow',
                            }}
                        >
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                The only app you need for corporate workperks
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        // backgroundColor: 'purple',
                        paddingTop: isMobile ? '50px' : '50px',
                        paddingBottom: isMobile ? '0px' : '50px',
                    }}
                >
                    <Grid
                        sx={{
                            height: '40%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            // backgroundColor:'red'
                        }}
                    >
                        <img
                            alt='modulesMobile'
                            className='modules'
                            src='/imgs/sitegraphics/Mobile.Modules.png'
                            // @ts-ignore
                            style={{
                                // position: 'fixed',
                                // flexDirection: 'row',
                                width: '150px',
                                minWidth: isMobile ? '100px' : 'auto',
                                maxWidth: isMobile ? '100px' : 'auto',
                                height: 'auto',

                                // justifyContent: 'space-around',
                            }}

                            // autoPlay
                            // loop
                        />
                        <img
                            alt='modulesMobile'
                            className='modules'
                            src='/imgs/sitegraphics/calendar.png'
                            // @ts-ignore
                            style={{
                                // position: 'fixed',
                                // flexDirection: 'row',
                                width: '250px',
                                minWidth: isMobile ? '200px' : 'auto',
                                maxWidth: isMobile ? '200px' : 'auto',
                                height: 'auto',
                                boxShadow: '10px 10px 5px #ccc',
                                // justifyContent: 'space-around',
                            }}

                            // autoPlay
                            // loop
                        />
                    </Grid>

                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: isMobile ? '30px' : '0px',
                        }}
                    >
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Book Online
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Access a marketplace at the palm of your hand!
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Our user-friendly booking system gives you
                                access to book high-end experiences such as
                                boats, vehicles and properties.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                The 7DAY App is accessible via the web and
                                mobile making it quick and easy for you to
                                access experiences and request bookings on the
                                go.
                            </Typography>
                        </Grid>
                        <Grid
                            sx={{ display: 'flex', margin: '30px 0px 0px 0px', justifyContent:isMobile ? 'center' : 'flex-start'}}
                        >
                            <Grid>
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://apps.apple.com/au/app/7day-app/id6470282265?itsct=apps_box_link&itscg=30200`}
                                >
                                    {' '}
                                    <img
                                        alt='primarylogo'
                                        src='/appleBadge.jpg'
                                        style={{
                                            // padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '50px',
                                        }}
                                    />
                                </Link>
                            </Grid>
                            <Grid>
                                <Link
                                    style={{ color: '#787878' }}
                                    target='_blank'
                                    to={`https://play.google.com/store/apps/details?id=com.sevenday.mobile&pcampaignid=web_share7DAY`}
                                >
                                    <img
                                        alt='primarylogo'
                                        src='/googleBadge.png'
                                        style={{
                                            padding: '0px 50px 0px 10px',
                                            width: 'auto',
                                            height: '50px',
                                            // backgroundColor: 'red',
                                        }}
                                    />
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        // backgroundColor: 'purple',
                        paddingTop: '50px',
                        paddingBottom: isMobile ? '0px' : '50px',
                    }}
                >
                    {isMobile ? (
                        <>
                            <Grid
                                sx={{
                                    height: '40%',
                                    width: isMobile ? '100%' : '50%',
                                    display: 'flex',
                                    justifyContent: 'left',
                                    paddingBottom: '30px',
                                }}
                            >
                                <img
                                    alt='calendar'
                                    className='modules'
                                    src='/imgs/sitegraphics/managebookingscalendar.png'
                                    // @ts-ignore
                                    style={{
                                        // position: 'fixed',
                                        // flexDirection: 'row',
                                        width: '400px',
                                        minWidth: isMobile ? '300px' : 'auto',
                                        maxWidth: isMobile ? '400px' : 'auto',
                                        height: 'auto',
                                        boxShadow: '10px 10px 5px #ccc',
                                        // justifyContent: 'space-around',
                                    }}

                                    // autoPlay
                                    // loop
                                />
                            </Grid>
                        </>
                    ) : null}
                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid
                            sx={{
                                margin: '0px 0px 30px 0px',
                                // display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            {' '}
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Manage your bookings
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Access and manage your past, current and future
                                bookings.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Our in-built calendar functionality allows you
                                to oversee your organisations:
                                <ul>
                                    <li>Bookings</li>
                                    <li>Trip details</li>
                                    <li>Manage upcoming bookings</li>
                                    <li>Export and share</li>
                                </ul>
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                7DAY also seamlessly integrates with other
                                calendars such as Outlook, Google and
                                Microsoft365.
                            </Typography>
                        </Grid>
                    </Grid>
                    {isMobile ? null : (
                        <Grid
                            sx={{
                                height: '40%',
                                width: isMobile ? '100%' : '50%',
                                display: 'flex',
                                justifyContent: 'left',
                            }}
                        >
                            <img
                                alt='calendar'
                                className='modules'
                                src='/imgs/sitegraphics/managebookingscalendar.png'
                                // @ts-ignore
                                style={{
                                    // position: 'fixed',
                                    // flexDirection: 'row',
                                    width: isLaptop ? '550px' : '750px',
                                    height: 'auto',
                                    boxShadow: '10px 10px 5px #ccc',
                                    // justifyContent: 'space-around',
                                }}

                                // autoPlay
                                // loop
                            />
                        </Grid>
                    )}
                </Grid>
                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        // backgroundColor: 'purple',
                        paddingTop: '50px',
                        paddingBottom: isMobile ? '0px' : '50px',
                    }}
                >
                    <Grid
                        sx={{
                            height: '40%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            paddingBottom: isMobile ? '30px' : '0px',
                        }}
                    >
                        <img
                            alt='notifications'
                            className='modules'
                            src='/imgs/sitegraphics/notifications.png'
                            // @ts-ignore
                            style={{
                                // position: 'fixed',
                                // flexDirection: 'row',
                                width: isLaptop ? '300px' : '350px',
                                minWidth: isMobile ? '200px' : 'auto',
                                maxWidth: isMobile ? '250px' : 'auto',
                                height: 'auto',
                                boxShadow: '10px 10px 5px #ccc',
                                // justifyContent: 'space-around',
                            }}

                            // autoPlay
                            // loop
                        />
                    </Grid>

                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <Grid
                            sx={{
                                margin: '0px 0px 30px 0px',
                                // display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            {' '}
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Real-time notifications
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Be in the know with our notification center!
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Keep up to date and informed with your bookings
                                by receiving real-time notifications via our
                                app.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Our notification center will allow you to sit
                                back and manage less as it informs you of:
                                <ul>
                                    <li>Approved bookings</li>
                                    <li>Declined bookings</li>
                                    <li>Cancelled bookings</li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        // backgroundColor: 'purple',
                        paddingTop: '50px',
                        paddingBottom: isMobile ? '0px' : '50px',
                    }}
                >
                    <Grid
                        sx={{
                            height: '40%',
                            width: isMobile ? '100%' : '50%',
                            justifyContent: 'left',
                            display: { xs: 'flex', md: 'none' },
                            paddingBottom: '30px',
                        }}
                    >
                        <img
                            alt='users'
                            className='modules'
                            src='/imgs/sitegraphics/users.png'
                            // @ts-ignore
                            style={{
                                // position: 'fixed',
                                // flexDirection: 'row',
                                width: isLaptop ? '200px' : '750px',
                                minWidth: isMobile ? '30px' : 'auto',
                                maxWidth: isMobile ? '400px' : 'auto',
                                height: 'auto',
                                boxShadow: '10px 10px 5px #ccc',
                                // justifyContent: 'space-around',
                            }}

                            // autoPlay
                            // loop
                        />
                    </Grid>
                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            // paddingTop: isMobile ? '0px' : '50px',
                            // paddingBottom: isMobile ? '0px' : '50px',
                        }}
                    >
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                User Management
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Securely invite staff to have exclusive access
                                to the app.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                With 7DAY, you can add, view and edit your
                                staff's access to 7DAY.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                With two types of security profiles, we give you
                                the control on what staff members can do.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        sx={{
                            height: '40%',
                            width: isMobile ? '100%' : '50%',
                            justifyContent: 'left',
                            display: { xs: 'none', md: 'flex', lg: 'flex' },
                        }}
                    >
                        <img
                            alt='modulesMobile'
                            className='modules'
                            src='/imgs/sitegraphics/users.png'
                            // @ts-ignore
                            style={{
                                // position: 'fixed',
                                // flexDirection: 'row',
                                width: isLaptop ? '600px' : '750px',
                                minWidth: isMobile ? '30px' : 'auto',
                                maxWidth: isMobile ? '400px' : 'auto',
                                height: 'auto',
                                boxShadow: '10px 10px 5px #ccc',
                                // justifyContent: 'space-around',
                            }}

                            // autoPlay
                            // loop
                        />
                    </Grid>
                </Grid>
                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        // backgroundColor: 'purple',
                        paddingTop: '50px',
                        paddingBottom: '50px',
                    }}
                >
                    <Grid
                        sx={{
                            height: isMobile ? '100%' : '40%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flextDirection: isMobile ? 'row' : 'column',
                            justifyContent: 'left',
                        }}
                    >
                        <Grid>
                            {' '}
                            <img
                                alt='reportList'
                                className='modules'
                                src='/imgs/sitegraphics/reportlist.png'
                                // @ts-ignore
                                style={{
                                    // position: 'fixed',
                                    // flexDirection: 'row',
                                    width: isLaptop ? '400px' : '500px',
                                    minWidth: isMobile ? '30px' : 'auto',
                                    maxWidth: isMobile ? '400px' : 'auto',
                                    height: 'auto',
                                    boxShadow: '10px 10px 5px #ccc',
                                    // justifyContent: 'space-around',
                                }}

                                // autoPlay
                                // loop
                            />
                        </Grid>
                        <Grid sx={{ display: { xs: 'none', md: 'flex' } }}>
                            <img
                                alt='reportMobile'
                                className='modules'
                                src='/imgs/sitegraphics/reportlistgraph.png'
                                // @ts-ignore
                                style={{
                                    // position: 'fixed',
                                    // flexDirection: 'row',
                                    // boxShadow: '10px 10px 5px #ccc',
                                    width: '150px',
                                    height: 'auto',
                                    // justifyContent: 'space-around',
                                }}

                                // autoPlay
                                // loop
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            paddingTop: isMobile ? '50px' : '0px',
                        }}
                    >
                        <Grid
                            sx={{
                                margin: '0px 0px 30px 0px',
                                // display: { xs: 'none', md: 'flex' },
                            }}
                        >
                            {' '}
                            <Typography
                                variant='h6'
                                component='h2'
                                color='#5cb5b3'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    // textAlign: 'left',
                                    textTransform: 'uppercase',
                                }}
                            >
                                Reporting
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Understand your employees needs through our
                                range of reports.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Look no more and stop wondering what your
                                employees want for workperks. Our app gives you
                                access to a suite of reports so you can gather
                                intel about what your employees want.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                <ul>
                                    <li>
                                        Customisable reports to cater for your
                                        needs
                                    </li>
                                    <li>Monthly and financial year reports</li>
                                    <li>and MORE! </li>
                                </ul>
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>{' '}
            </Container>
        </Box>
    );
};
