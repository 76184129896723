import React from "react";
import ReactDOM from "react-dom";
import "../src/app/index.css";
import App from "../src/app/App";
// import { BrowserRouter, Router } from "react-router-dom";
// import history from './utils/history';
import { Provider } from "react-redux";
import store from "app/slice/store";

ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <App />
    </React.StrictMode>{" "}
  </Provider>,
  document.getElementById("root")
);
