import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';


export default function ManagingProfile() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        To update your profile information:
                        <ul>
                            <li>Log into the 7DAY App</li>
                            <li>
                                Click on the profile icon in the top right
                                corner and edit your profile
                            </li>
                            <li>
                                Click on the "pencil" icon to edit your profile
                            </li>
                        </ul>
                        Note: If you are not an admin, you cannot edit:
                        <ul>
                            <li>Company details</li>
                            <li>Level of access</li>
                            <li>Your status</li>
                            <li>Your Email</li>
                        </ul>
                        To change these, you will need to request this from
                        someone in your company who has admin access.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
