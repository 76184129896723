import React from 'react';
import Typography from '@mui/material/Typography';
import { Box, Container, Grid, useMediaQuery } from '@mui/material';
import { Link } from 'react-scroll';
import { CustomButtonContained } from 'Layout/CustomButton';
import AvailablePositions from './AvailablePositions';

export const CareersContent = () => {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const isIpad = useMediaQuery('(max-width: 1200px)');
    const isLaptop = window.innerWidth < 2560 && window.innerWidth > 1030;

    return (
        <Box
            id='careers'
            sx={{
                width: isIpad ? '100%' : 'auto',
                height: isIpad || isMobile ? '100vh' : 'auto',
                backgroundImage: isMobile
                    ? 'linear-gradient(-62deg,#f6f8f9 54.5% ,#fff 7%)'
                    : isIpad
                    ? 'linear-gradient(-48deg,#f6f8f9 50.5% ,#fff 7%)'
                    : 'linear-gradient(-25deg,#f6f8f9 55.5% ,#fff 7%)',
                // '   conic-gradient(#fff, #f6f8f9)',
                // backgroundColor: 'white',
                // display: 'flex',
                // flexDirection: 'column',
                paddingBottom: '45px',
            }}
        >
            <Container
                fixed
                maxWidth={isLaptop ? 'lg' : 'xl'}
                sx={{
                    height: isMobile ? 'auto' : '100%',
                    paddingTop: isMobile ? '110px' : '190px',
                    paddingBottom: isMobile ? '100px' : '45px',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        height: '25%',
                        justifyContent: 'left',
                        alignItems: 'left',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            component={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            variant={isMobile ? 'h3' : isLaptop ? 'h2' : 'h1'}
                            color='#787878'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            {' '}
                            Careers
                        </Typography>
                    </Grid>

                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            variant='h6'
                            component='h2'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            Where work meets adventure!
                        </Typography>
                    </Grid>
                </Grid>
                {/* {isIpad ? null : ( */}
                <Grid
                    sx={{
                        width: '100%',
                        height: '25%',
                        paddingTop: '50px',
                    }}
                >
                    <Grid>
                        <img
                            alt='boat'
                            src='/imgs/sitegraphics/buggy.jpg'
                            style={{
                                width:
                                isLaptop
                                   ? '1140px'
                                   : '100%',
                                   minWidth: isMobile ? '350px': 'auto',
                                   maxWidth: isMobile ? '400px': 'auto',
                                height: isMobile ||isIpad ? 'auto' : isLaptop? 'auto':'550px',
                           
                            }}
                        />
                    </Grid>
                </Grid>
                {/* )} */}

                <Grid
                    sx={{
                        height: '50%',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        // backgroundColor: 'purple',
                        paddingTop: '50px',
                        paddingBottom: isMobile ? '10px' : '50px',
                    }}
                >
                    {' '}
                    {isMobile ? null : (
                        <Grid
                            sx={{
                                width: isIpad ? '40%' : 'auto',
                                height: '100%',
                                // backgroundColor: 'purple',
                            }}
                        >
                            <Grid
                                sx={{
                                    height: isIpad ? '25%' : '40%',
                                    width: isIpad ? '100%' : '60%',
                                    paddingRight: '0px',
                                    // backgroundColor: 'purple',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontSize: isIpad ? '30px' : '40px',
                                        color: '#5cb5b3',
                                        fontFamily: 'Open Sans',
                                        // textTransform: 'uppercase',
                                    }}
                                >
                                    Awaken your adventurous side!
                                </Typography>
                            </Grid>

                            <Grid
                                sx={{
                                    height: isIpad ? '25%' : '60%',
                                    textAlign: 'left',
                                    paddingTop: isIpad ? '30px' : '30px',
                                }}
                            >
                                <Link
                                    to={'contact'}
                                    spy={true}
                                    smooth={true}
                                    offset={100}
                                    duration={2000}
                                    delay={350}
                                >
                                    {' '}
                                    <CustomButtonContained>
                                        Get In Touch
                                    </CustomButtonContained>
                                </Link>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        sx={{
                            height: '100%',
                            width: isMobile ? '100%' : isIpad ? '60%' : '50%',
                            display: 'flex',
                            flexDirection: 'column',
                            // backgroundColor: 'green',
                        }}
                    >
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            {' '}
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                Corporate culture matters. Smart creatives care
                                about the place they work.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 30px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                7DAY is a startup company looking for tech
                                superstars to revolutionize the way businesses
                                offer work perks to their employees. We offer
                                each employee the chance to grow in their career
                                in a supportive and flexible environment.
                            </Typography>
                        </Grid>
                        <Grid sx={{ margin: '0px 0px 0px 0px' }}>
                            <Typography
                                sx={{
                                    fontFamily: 'Open Sans',
                                    color: '#787878',
                                }}
                            >
                                {' '}
                                Based in Australia, we have a high performing
                                culture and appreciation is often shown for your
                                hard work as well as being rewarded with the
                                opportunity to work anywhere at any time or from
                                our office.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    sx={{
                        height: isMobile ? 'auto' : '50%',
                        width: '100%',
                        display: { xs: 'none', md: 'flex', lg: 'flex' },
                        flexDirection: 'column',
                        // backgroundColor: 'purple',
                        paddingTop: isMobile ? '0px' : '50px',
                        paddingBottom: '50px',
            
                    }}
                >
                    <Grid
                        sx={{
                            width: '100%',
                            height: 'auto',
                        }}
                    >
                        <Typography
                            variant='h6'
                            component='h2'
                            color='#5cb5b3'
                            sx={{
                                fontFamily: 'Open Sans',
                                // textAlign: 'left',
                                textTransform: 'uppercase',
                            }}
                        >
                            Available Positions:
                        </Typography>
                    </Grid>
                    <Grid sx={{ margin: '30px 0px 30px 0px' }}>
                        {' '}
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                            }}
                        >
                            We are currently not hiring.
                        </Typography>
                        {/* <AvailablePositions/> */}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};
