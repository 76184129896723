import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

export default function GettingStarted() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        paddingBottom: '20px',
                        fontSize: '14px',
                    }}
                >
                    To get started, there are three ways to access the
                    application:
                    <ul>
                        <li>
                            Direct link to the application{' '}
                            <Link
                                style={{
                                    color: '#787878',
                                    textDecoration: 'underline',
                                }}
                                target='_blank'
                                to={ 'https://app.7-day.com/login' }
                            >
                                www.app.7-day.com
                            </Link>
                        </li>
                        <li>
                            Via{' '}
                            <Link
                                style={{
                                    color: '#787878',
                                    textDecoration: 'underline',
                                }}
                                target='_blank'
                                to={'https://www.7-day.com/'}
                        
                            >
                                www.7-day.com
                            </Link>{' '}
                        </li>
                        <li>Downloading the app from the app store</li>
                    </ul>
                    <Grid
                        sx={{
                            display: 'flex',
                            width: '100%',
                            flexDirection: 'row',
                        }}
                    >
                        <Grid sx={{ width: isMobile? '50%': '12%' ,    }}>
                            <Link
                                style={{ color: '#787878' }}
                                target='_blank'
                                to={`https://apps.apple.com/au/app/7day-app/id6470282265?itsct=apps_box_link&itscg=30200`}
                            >
                                {' '}
                                <img
                                    alt='primarylogo'
                                    src='/AppleWhite.png'
                                    style={{
                                        padding: '0px 50px 0px 10px',
                                        width: 'auto',
                                        height: isMobile? '40px': '50px',
                                        // backgroundColor: 'red',
                                    }}
                                />
                            </Link>
                        </Grid>
                        <Grid sx={{ width: isMobile? '50%':'12%',   }}>
                            <Link
                                style={{ color: '#787878' }}
                                target='_blank'
                                to={`https://play.google.com/store/apps/details?id=com.sevenday.mobile&pcampaignid=web_share7DAY`}
                            >
                                <img
                                    alt='primarylogo'
                                    src='/googleWhite.png'
                                    style={{
                                        padding: '0px 50px 0px 10px',
                                        width: 'auto',
                                        height: isMobile? '40px': '50px',
                                        // backgroundColor: 'red',
                                    }}
                                />
                            </Link>
                        </Grid>
                        <Grid sx={{width: isMobile? '0%': '70%'}}></Grid>
                    </Grid>
                    <Grid>
                        {' '}
                        <img
                            alt='primarylogo'
                            src='/QRStorebadge.png'
                            style={{
                                // padding: '0px 50px 0px 10px',
                                width: isMobile? '140px': '150px',
                                height: 'auto',
                                // backgroundColor: 'red',
                            }}
                        />
                    </Grid>
                    Either of these options will ask you to log in.
                    {''}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    If you do not have an account with 7DAY, please speak with
                    your administrator or alternatively email {''}
                    <Link
                        style={{
                            color: '#787878',
                            textDecoration: 'underline',
                        }}
                        to='#'
                        // @ts-ignore
                        onClick={() =>
                            // @ts-ignore
                            (window.location = 'mailto: support@7-day.com')
                        }
                    >
                        support@7-day.com
                    </Link>{' '}
                </Typography>
            </Grid>
        </Grid>
    );
}
