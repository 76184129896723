import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "../feature/Pages/HomePage";
// import history from '../utils/history';
// import LandingLayout from 'Layout/LandingLayout';
import { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { HelpPage } from "feature/Pages/HelpPage";

const LandingLayout = React.lazy(() => import("Layout/LandingLayout"));

const useStyles = makeStyles(() =>
  createStyles({
    loading: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "70vh",
    },
  })
);

export default function CustomRouter() {
  // @ts-ignore
  const classes = useStyles();
  return (
    <>
      <Suspense
        fallback={
          <div className={classes.loading}>
            {" "}
            <CircularProgress />
          </div>
        }
      >
        <LandingLayout>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/help" element={<HelpPage />} />
          </Routes>
        </LandingLayout>
      </Suspense>
    </>
  );
}
