import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';


export default function CancelABooking() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
        
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Users of the 7DAY App can cancel their own booking
                            only. Admin Users can cancel their own bookings and
                            the bookings of other users. Depending on the status
                            of the booking determines what action you can take:
                            <ul>
                                <li>PENDING - You can edit or cancel</li>

                                <li>APPROVED - You can cancel</li>
                                <li>COMPLETED - No actions can be made</li>
                                <li>DECLINED - No actions can be made</li>
                                <li>CANCELLED - No actions can be made</li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To make a change:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Bookings'</li>
                                <li>
                                    Select the booking you want to cancel from
                                    the list or apply filters to narrow the
                                    search
                                </li>
                                <li>Scroll down to the bottom of the page</li>
                                <li>Select 'Cancel'</li>
                                <li>
                                    Upon selection, you are required to provide a
                                    reason for the cancellation
                                </li>
                                <li>
                                    Once you confirm the cancellation, the
                                    booking status will change to 'Cancelled'
                                    and the owner of the experience will be
                                    notified.
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
            
        </>
    );
}
