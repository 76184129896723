// @ts-ignore
import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
    name: 'componentSelectedSlice',
    initialState: {
        value: {
            name: 'home',
        },
    },
    reducers: {
        updateComponentName: (state, action) => {
            state.value.name = action.payload;
        },
    },
});
// to set the value
export const { updateComponentName } = slice.actions;
// to get the value
export const componentSelectedSliceValue = state =>
    state.componentSelectedSlice.value.name;
export default slice.reducer;
