import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function AddingAUser() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
            <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                <Grid>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            paddingBottom: '20px',
                            fontSize: '14px',
                        }}
                    >
                        Not just you? You can add and manage your staffs access
                        to the site with two different levels of access.
                        <ul>
                            <li>
                                ADMIN – Has access to add, edit and delete
                                experiences, bookings and other staff
                            </li>

                            <li>
                                USER – Has access to view experiences, bookings
                                and reports
                            </li>
                        </ul>
                        If you're not an admin, you will not have access to add
                        another user. In order to add a new user, please request
                        admin access from your human resources
                        department/company administrator or alternatively ask
                        someone who has admin access.
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        To create a new user:
                    </Typography>

                    <Typography
                        sx={{
                            fontFamily: 'Open Sans',
                            color: '#787878',
                            fontSize: '14px',
                        }}
                    >
                        <ol>
                            <li>Login to 7DAY</li>
                            <li>
                                From the top right corner of the app, click on
                                the cog wheel icon
                            </li>
                            <li>Select 'Users'</li>
                            <li>Select 'Add Member'</li>
                            <li>Complete all mandatory fields with a *</li>
                            <li>Click 'Save New User'</li>
                            <li>
                                Once the user has been saved, a 'Send
                                Invitation' button will appear next to the users
                                email. Click on this to send the invite to the
                                user. The user will need to click on the link in
                                order to set a password. Once the password has
                                been set by the user, they will be able to log
                                into the 7DAY application.
                            </li>
                        </ol>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
}
