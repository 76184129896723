import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';

export default function MakeABooking() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
        
           
       
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Users of the 7DAY App can make a booking request for
                            any available listed experience on the app. Requests
                            are sent to the owner of the experience to approve.
                            Once approved, you will be notified via email or the
                            notification section (the "bell" icon located in the
                            top right corner of the app).
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                               To make a booking:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on ‘Experiences’</li>
                                <li>Select the experience you want to book</li>
                                <li>
                                    On the bottom right corner of the listed
                                    experience, select a date (or date range -
                                    depending on the experience type)
                                </li>
                                <li>Click 'Request'</li>
                                <li>Complete all the mandatory fields</li>
                                <li>
                                    Select 'Request to book' to submit a request{' '}
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
         
        </>
    );
}
