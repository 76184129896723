import React, { useEffect, useState } from 'react';
import { VisionHeroContent } from 'feature/components/Vision/VisionHeroContent';
import { VisionContent } from 'feature/components/Vision/VisionContent';
import { updateComponentName } from 'app/slice/componentSelectedSlice';
import { useDispatch } from 'react-redux';
import { CareersContent } from 'feature/components/Careers/CareersContent';
import { CareersHeroContent } from 'feature/components/Careers/CareersHeroContent';
import { ContactContent } from 'feature/components/Contact/ContactContent';
import { HomeComponent } from 'feature/components/Home/HomeComponent';
import { ProductContent } from 'feature/components/Product/ProductContent';

export const HomePage = () => {
    const dispatch = useDispatch();
    //to know what component was clicked
    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        const moreThan = position > 833 
        setScrollPosition(position);
        if (moreThan) {
            dispatch(updateComponentName({ value: 'vision' }));
        } else {
            dispatch(updateComponentName({ value: 'home' }));
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div   onScroll={handleScroll} >
            <HomeComponent />
            <VisionHeroContent />
            <VisionContent />
            <ProductContent/>
            <CareersContent />
            <CareersHeroContent />
            <ContactContent />
        </div>
    );
};
