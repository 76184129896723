import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';


export default function ManageABooking() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <>
     
                <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
                    <Grid>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                paddingBottom: '20px',
                                fontSize: '14px',
                            }}
                        >
                            Users of the 7DAY App can manage their own booking
                            only. Admin Users can manage their own bookings and
                            the bookings of other users. Depending on the status
                            of the booking determines what action you can take:
                            <ul>
                                <li>PENDING - You can edit or cancel</li>

                                <li>APPROVED - You can cancel</li>
                                <li>COMPLETED - No actions can be made</li>
                                <li>DECLINED - No actions can be made</li>
                                <li>CANCELLED - No actions can be made</li>
                            </ul>
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            To make a change:
                        </Typography>

                        <Typography
                            sx={{
                                fontFamily: 'Open Sans',
                                color: '#787878',
                                fontSize: '14px',
                            }}
                        >
                            <ol>
                                <li>Login to 7DAY</li>
                                <li>From the menu, click on 'Bookings'</li>
                                <li>
                                    Select the booking you want to manage from
                                    the list or apply filters to narrow the
                                    search
                                </li>
                                <li>Scroll down to the bottom of the page</li>
                                <li>
                                    Select the action you would like to perform
                                </li>
                            </ol>
                        </Typography>
                    </Grid>
                </Grid>
          
        </>
    );
}
