import { Grid,  useMediaQuery } from '@mui/material';
import React from 'react';
import SupportComponentPage from '../SupportComponent';

export default function Support() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
           
            <Grid>
                <SupportComponentPage/>
            </Grid>
        </Grid>
    );
}
