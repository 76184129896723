import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Grid,
    Typography,
    useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CancelABooking from './Bookings/CancelABooking';
import MakeABooking from './Bookings/MakeABooking';
import ManageABooking from './Bookings/ManageABooking';
import ViewBooking from './Bookings/ViewBooking';
import GettingStarted from './GettingStarted';
import Support from './Support';
import AddingAUser from './Users-Profile/AddingAUser';
import ChangePassword from './Users-Profile/ChangePassword';
import ManagingAUser from './Users-Profile/ManagingAUser';
import ManagingProfile from './Users-Profile/ManagingProfile';
import ResetPassword from './Users-Profile/ResetPassword';

const helpDocsGettingStarted = [
    {
        helpDocItem: <GettingStarted />,
        helpDocName: 'Getting Started',
        panel: 'gettingStarted1',
    },
    {
        helpDocItem: <Support />,
        helpDocName: 'Getting Help',
        panel: 'gettingStarted2',
    },
];

const helpDocsBookings = [
    {
        helpDocItem: <MakeABooking />,
        helpDocName: 'How to make a booking',
        panel: 'bookings1',
    },
    {
        helpDocItem: <ViewBooking />,
        helpDocName: 'How to view a booking',
        panel: 'bookings2',
    },
    {
        helpDocItem: <ManageABooking />,
        helpDocName: 'How to manage a booking',
        panel: 'bookings3',
    },
    {
        helpDocItem: <CancelABooking />,
        helpDocName: 'How to cancel a booking',
        panel: 'bookings4',
    },
];

const helpDocsUsers = [
    {
        helpDocItem: <AddingAUser />,
        helpDocName: 'How to add a new user',
        panel: 'users1',
    },
    {
        helpDocItem: <ManagingAUser />,
        helpDocName: 'How to manage a user (view, edit, disable/enable)',
        panel: 'users2',
    },
    {
        helpDocItem: <ChangePassword />,
        helpDocName: 'How to change a users password',
        panel: 'users3',
    },
    {
        helpDocItem: <ResetPassword />,
        helpDocName: 'How to reset a users password',
        panel: 'users4',
    },
];

const helpDocsProfile = [
    {
        helpDocItem: <ManagingProfile />,
        helpDocName: 'How to edit your profile',
        panel: 'profile1',
    },
    {
        helpDocItem: <ChangePassword />,
        helpDocName: 'How to change your password',
        panel: 'profile2',
    },
    {
        helpDocItem: <ResetPassword />,
        helpDocName: 'How to reset your password',
        panel: 'profile4',
    },
];

// const helpDocsExperiences = [
//     {
//         helpDocItem: <CreateAnExperience />,
//         helpDocName: 'How to create a new experience',
//         panel: 'experience1',
//     },
//     {
//         helpDocItem: <EditAnExperience />,
//         helpDocName: 'How to edit an experience',
//         panel: 'experience2',
//     },
//     {
//         helpDocItem: <DeleteAnExperience />,
//         helpDocName: 'How to delete an experience',
//         panel: 'experience3',
//     },
// ];

export default function DocumentationComponentPage() {
    const isMobile = useMediaQuery('(max-width: 770px)');
    const [expanded, setExpanded] = useState('');

    useEffect(() => {
        setExpanded('gettingStarted1');
    }, []);

    const handleChange =
        (panel) =>
        (event, isExpanded) => {
            setExpanded(isExpanded ? panel : true);
        };

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
            <Grid
                sx={{
                    textAlign: isMobile ? 'left' : 'right',
                    display: 'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                }}
            >
                <Grid
                    sx={{
                        width: '100%',
                        minHeight: 'auto',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        alignItems: isMobile ? 'left' : 'right',
                        justifyContent: isMobile ? 'flex-start' : 'flex-end',
                    }}
                ></Grid>
            </Grid>
            <Grid>
                {helpDocsGettingStarted.map((item) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                        key={item.helpDocName}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>

            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Bookings
                    </Typography>
                </Grid>

                {helpDocsBookings.map((item) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Users
                    </Typography>
                </Grid>

                {helpDocsUsers.map((item) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
            <Grid>
                <Grid
                    sx={{
                        height: '100px',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                    }}
                >
                    {' '}
                    <Typography
                        component='h3'
                        variant='h6'
                        color='#5cb5b3'
                        sx={{
                            fontFamily: 'Open Sans',
                            fontWeight: 550,
                            // textTransform: 'uppercase',
                        }}
                    >
                        Profile
                    </Typography>
                </Grid>

                {helpDocsProfile.map((item) => (
                    <Accordion
                        expanded={expanded === item.panel}
                        onChange={handleChange(item.panel)}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            sx={{
                                backgroundColor:
                                    expanded === item.panel ? '#f6f8f9' : '',
                            }}
                        >
                            <Typography
                                component='h3'
                                variant='h6'
                                color='#787878'
                                sx={{
                                    fontFamily: 'Open Sans',
                                    fontWeight: 550,
                                    // textTransform: 'uppercase',
                                }}
                            >
                                {item.helpDocName}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>{item.helpDocItem}</AccordionDetails>
                    </Accordion>
                ))}
            </Grid>
        </Grid>
    );
}
