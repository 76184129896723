import { Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';


export default function ViewBooking() {
    const isMobile = useMediaQuery('(max-width: 770px)');

    return (
        <Grid sx={{ marginTop: isMobile ? '30px' : '0px' }}>
        <Grid>
            <Typography
                sx={{
                    fontFamily: 'Open Sans',
                    color: '#787878',
                    paddingBottom: '20px',
                    fontSize: '14px',
                }}
            >
                There are multiple ways to view an individual or
                multiple booking:
                <ul>
                    <li>Via the bookings list</li>
                    <li>Via the bookings calendar</li>
                    <li>Via the reports/dashboard</li>
                    <li>Via the notification section</li>
                </ul>
            </Typography>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a booking (list view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Bookings'</li>
                        <li>
                            The initial view is a list view. This
                            will display all booking. You can narrow
                            the list by clicking on the filter icon
                            on the right.
                        </li>
                        <li>Select the booking you wish to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a booking (calendar view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Bookings'</li>
                        <li>
                            The initial view is a list view. To
                            change the view to calendar, click the
                            calendar icon on the right.
                        </li>
                        <li>
                            This will display all bookings for the
                            month. You can{' '}
                            <ul>
                                <li>
                                    Narrow the list by clicking on
                                    the filter icon on the right.
                                </li>
                                <li>
                                    Change the calendar view to day,
                                    week, month or list view
                                </li>
                            </ul>
                        </li>
                        <li>Select the booking you wish to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a booking (report view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>From the menu, click on 'Reports'</li>
                        <li>
                            Select the report relevant to what you
                            need. Alternatively, use the customisable
                            report 'Bookings Report'.
                        </li>
                        <li>
                            Make your relevant filter selections if
                            needed
                        </li>
                        <li>Click on the booking to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a booking (dashboard view):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>
                            Click on either:{' '}
                            <ul>
                                <li>
                                    the 7DAY icon in the top left
                                    corner of the app
                                </li>
                                <li>
                                    the profile icon in the top
                                    right corner of the app and
                                    select 'Dashboard'
                                </li>
                            </ul>
                        </li>
                        <li>
                            Select the dashboard stat relevant to
                            what you need.
                        </li>

                        <li>Click on the booking to view</li>
                    </ol>
                </Typography>
            </Grid>
            <Grid>
                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    To view a booking (notification section):
                </Typography>

                <Typography
                    sx={{
                        fontFamily: 'Open Sans',
                        color: '#787878',
                        fontSize: '14px',
                    }}
                >
                    <ol>
                        <li>Login to 7DAY</li>
                        <li>
                            Click on the notification 'bell' located
                            in the top right corner of the app
                        </li>

                        <li>Click on the booking to view</li>
                    </ol>
                </Typography>
            </Grid>
        </Grid>
    </Grid>
    );
}
